<template>
  <div class="flex justify-center md:justify-start items-center h-full">
    <div class="flex justify-center w-10">
      <a
        href="https://www.instagram.com/kindandconsciousuk/"
        class="current-color text-center mx-auto"
        target="_blank"
        rel="noopener"
        aria-label="Instagram | Kind & Conscious"
      >
        <span class="sr-only">
          Instagram
        </span>
        <svg
          class="h-6 md:h-5 w-6 md:w-5"
          fill="currentColor"
          viewBox="0 0 24 24"
          aria-hidden="true"
          data-icon="instagram"
          role="img"
        >
          <path
            fill-rule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clip-rule="evenodd"
          />
        </svg>
      </a>
    </div>
    <div class="flex justify-center w-10">
      <a
        href="https://twitter.com/KindConsciousUK"
        class="current-color text-center mx-auto"
        target="_blank" 
        rel="noopener"
        aria-label="X (Formally Twitter) | Kind & Conscious"
      >
        <span class="sr-only">
          X (Formally Twitter)
        </span>
        <svg
          class="h-5 md:h-5 w-5 md:w-5"
          fill="currentColor"
          viewBox="0 0 1200 1227"
          aria-hidden="true"
          data-icon="twitter"
          role="img"
        >
          <path 
            d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z" 
          />
        </svg>
      </a>
    </div>
    <div class="flex justify-center w-10">
      <a
        href="https://www.pinterest.co.uk/kindandconsciousUK/"
        class="current-color text-center mx-auto"
        target="_blank"
        rel="noopener"
        aria-label="Pinterest | Kind & Conscious"
      >
        <span class="sr-only">
          Pinterest
        </span>
        <svg
          class="h-6 md:h-5 w-6 md:w-5"
          fill="currentColor"
          viewBox="0 0 496 512"
          aria-hidden="true"
          data-icon="pinterest"
          role="img"
        >
          <path
            d="M496 256c0 137-111 248-248 248-25.6 0-50.2-3.9-73.4-11.1 10.1-16.5 25.2-43.5 30.8-65 3-11.6 15.4-59 15.4-59 8.1 15.4 31.7 28.5 56.8 28.5 74.8 0 128.7-68.8 128.7-154.3 0-81.9-66.9-143.2-152.9-143.2-107 0-163.9 71.8-163.9 150.1 0 36.4 19.4 81.7 50.3 96.1 4.7 2.2 7.2 1.2 8.3-3.3.8-3.4 5-20.3 6.9-28.1.6-2.5.3-4.7-1.7-7.1-10.1-12.5-18.3-35.3-18.3-56.6 0-54.7 41.4-107.6 112-107.6 60.9 0 103.6 41.5 103.6 100.9 0 67.1-33.9 113.6-78 113.6-24.3 0-42.6-20.1-36.7-44.8 7-29.5 20.5-61.3 20.5-82.6 0-19-10.2-34.9-31.4-34.9-24.9 0-44.9 25.7-44.9 60.2 0 22 7.4 36.8 7.4 36.8s-24.5 103.8-29 123.2c-5 21.4-3 51.6-.9 71.2C65.4 450.9 0 361.1 0 256 0 119 111 8 248 8s248 111 248 248z"
          />
        </svg>
      </a>
    </div>
    <div class="flex justify-center w-10">
      <a
        href="mailto:hello@kindandconscious.co.uk"
        class="current-color text-center mx-auto"
        target="_blank"
        rel="noopener"
        aria-label="Email | Kind & Conscious"
      >
        <span class="sr-only">
          Email
        </span>
        <svg
          class="h-6 md:h-5 w-6 md:w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
          data-icon="email"
          role="img"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
          />
        </svg>
      </a>
    </div>
  </div>
</template>