<script setup lang="ts">
import { useWindowScroll } from '@vueuse/core'

const { y } = useWindowScroll()

const fixed = computed(() => {
  return y.value > 150
})

const route = useRoute()

const showHomeButton = computed(() => {
  return route.name !== 'index'
})

const showCart = computed(() => {
  return true
})
</script>

<template>
  <div 
    class="w-full fixed top-0 z-100 transition-all duration-300 ease-in-out" 
    :class="{
      'bg-white bg-opacity-85 backdrop-filter backdrop-blur-md': fixed,
      'bg-transparent': !fixed
    }"
  >
  <div 
    class="bg-blue-500 bg-opacity-50 transition-all duration-300 ease-in-out"
    :class="{
      'bg-blue-600 bg-opacity-100': fixed
    }"
  >
      <div class="mx-auto flex h-10 max-w-7xl items-center justify-center px-4 sm:px-6 lg:px-8">
        <p class="text-center text-sm font-medium text-white lg:flex-none">Complimetary free delivery on orders over £60</p>
      </div>
    </div>
    <div class="flex justify-between items-center p-4">
      <div>
        <NuxtLink
          :to="{ 'name': 'index' }"
          class="text-white hover:text-blue-700 transition-opacity duration-800 ease-in-out"
          :class="{
            'opacity-100': showHomeButton || fixed,
            'opacity-0': !showHomeButton
          }"
          title="Go to home page | Kind & Conscious"
          aria-label="Go to home page | Kind & Conscious"
          aria-hidden="true"
        >
          <KindAndConsciousStampLogo 
            class="h-12 w-auto mx-auto transition-all duration-500 ease-in-out"
            :class="{
              'text-opacity-100 text-blue-500': fixed,
              'text-opacity-10 text-white': !fixed
            }"
            title="Logo | Kind & Conscious"
            aria-label="Logo | Kind & Conscious"
            aria-hidden="true"
          />
        </NuxtLink>
      </div>
      <div>
        <CartPopoverPanel 
          v-if="showCart"
          :fixed="fixed"
        />
      </div>
    </div>
  </div>
</template>