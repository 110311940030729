<template>
  <svg 
    viewBox="0 0 105 105"
    xmlns="http://www.w3.org/2000/svg" 
    aria-label="Kind & Conscious Logo"
    aria-hidden="true"
  >
    <circle 
      cx="52.441" 
      cy="52.441" 
      r="52.441"
      fill="currentColor" 
      fill-rule="evenodd"
    />
    <path 
      class="text-white"
      d="M1.038,62.864 C2.755,61.425 4.35,60.09 5.415,59.208 C12.704,53.168 23.004,46.454 32.298,41.441 C44.34,34.89 53.407,31.654 64.104,30.515 C65.857,30.344 67.67,30.23 69.423,30.23 C73.308,30.23 77.153,30.667 80.652,31.594 C84.524,32.621 87.973,34.249 90.584,36.553 C90.525,37.522 89.59,38.718 88.655,38.718 C88.371,38.718 87.917,38.565 87.917,38.565 C87.917,38.565 70.163,31.464 60.67,41.348 C55.488,46.744 57.256,54.061 57.319,54.369 C59.008,62.626 65.275,67.227 70.301,69.457 C73.461,70.582 79.8,72.219 88.509,71.516 C89.292,71.453 89.686,71.428 90.075,71.743 C90.846,72.366 90.561,73.96 89.96,74.834 C90.061,74.855 78.609,75.403 69.25,70.781 C55.945,64.209 54.155,52.095 56.111,45.627 C58.118,38.99 64.695,33.235 72.728,33.258 C73.027,33.265 73.325,33.274 73.621,33.284 C73.321,33.267 73.024,33.259 72.728,33.258 C59.304,32.934 42.298,35.45 16.412,53.101 C12.145,56.01 6.107,60.804 1.419,64.596 C1.282,64.023 1.155,63.446 1.038,62.864 Z"
      fill="currentColor" 
      fill-rule="evenodd"
    />
  </svg>
</template>