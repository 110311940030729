<template>
  <svg 
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 740 740" 
  >
    <g id="Artboard" transform="translate(-650.000000, -554.000000)" fill="none">
        <path d="M1019.94992,1263.87542 C1207.72673,1263.87542 1359.94992,1111.65224 1359.94992,923.875421 C1359.94992,736.098606 1207.72673,583.875421 1019.94992,583.875421 C832.173101,583.875421 679.949916,736.098606 679.949916,923.875421 C679.949916,1111.65224 832.173101,1263.87542 1019.94992,1263.87542 Z" id="Oval" transform="translate(1019.949916, 923.875421) rotate(37.000000) translate(-1019.949916, -923.875421) "></path>
        <g id="IN-CHARLESTOWN" transform="translate(866.072763, 826.757711)" fill="currentColor" fill-rule="nonzero">
            <path d="M0.149238771,398.838376 C0.149238771,398.838376 23.1825318,354.854403 23.1825318,354.854403 C23.5304668,354.189992 24.4415777,353.905164 25.1059881,354.253099 C25.1059881,354.253099 29.4910973,356.54947 29.4910973,356.54947 C30.1555077,356.897405 30.4403357,357.808516 30.0924008,358.472926 C30.0924008,358.472926 7.05910769,402.4569 7.05910769,402.4569 C6.71117275,403.12131 5.80006188,403.406138 5.13565141,403.058203 C5.13565141,403.058203 0.750542283,400.761832 0.750542283,400.761832 C0.0861318095,400.413897 -0.19869617,399.502787 0.149238771,398.838376 Z" id="Path"></path>
            <path d="M31.4103013,412.442797 C31.4103013,412.442797 47.6601878,364.65507 47.6601878,364.65507 C47.9016423,363.945 48.7338691,363.594253 49.4439393,363.835708 C49.4439393,363.835708 51.2191149,364.439344 51.2191149,364.439344 C51.2191149,364.439344 69.9151481,410.563994 69.9151481,410.563994 C69.9151481,410.563994 69.9861551,410.588139 69.9861551,410.588139 C69.9861551,410.588139 81.406952,377.001817 81.406952,377.001817 C81.6484065,376.291747 82.4337717,375.845847 83.214849,376.111447 C83.214849,376.111447 87.7592984,377.656756 87.7592984,377.656756 C88.4693687,377.898211 88.8911228,378.754583 88.6496684,379.464653 C88.6496684,379.464653 72.3997819,427.25238 72.3997819,427.25238 C72.1583274,427.96245 71.3261006,428.313197 70.6160304,428.071743 C70.6160304,428.071743 68.7698477,427.443961 68.7698477,427.443961 C68.7698477,427.443961 50.4118509,380.325212 50.4118509,380.325212 C50.4118509,380.325212 50.3408438,380.301067 50.3408438,380.301067 C50.3408438,380.301067 38.5820107,414.881487 38.5820107,414.881487 C38.3405562,415.591558 37.555191,416.037457 36.7741137,415.771857 C36.7741137,415.771857 32.3006712,414.250694 32.3006712,414.250694 C31.590601,414.00924 31.1688468,413.152867 31.4103013,412.442797 Z" id="Path"></path>
            <path d="M123.68231,409.365834 C124.180042,394.374094 136.649067,382.706418 151.640806,383.20415 C159.211635,383.455504 164.599006,386.185772 169.549366,390.777564 C170.204085,391.399632 170.174221,392.299136 169.554643,392.878897 C169.554643,392.878897 166.14447,396.142537 166.14447,396.142537 C165.597361,396.799745 164.922733,396.777347 164.270503,396.08032 C160.849479,392.890047 156.116827,390.781846 151.469388,390.62755 C140.750294,390.271671 132.356115,398.99794 132.007703,409.492158 C131.659291,419.986376 139.533638,429.177725 150.252732,429.533603 C155.724717,429.715275 159.619782,427.668395 163.30988,425.014378 C164.001929,424.512065 164.674069,424.609422 165.111378,424.999147 C165.111378,424.999147 168.447488,428.486767 168.447488,428.486767 C169.029737,429.031387 168.847467,430.000873 168.305336,430.508163 C163.043893,435.286209 156.520296,437.395904 149.848972,437.174413 C134.857232,436.676681 123.184578,424.357574 123.68231,409.365834 Z" id="Path"></path>
            <path d="M190.446247,433.331757 C190.446247,433.331757 183.007808,384.242123 183.007808,384.242123 C182.895444,383.500588 183.461699,382.73208 184.203235,382.619716 C184.203235,382.619716 189.097367,381.87812 189.097367,381.87812 C189.913056,381.75452 190.607411,382.332012 190.719774,383.073547 C190.719774,383.073547 193.854706,403.76238 193.854706,403.76238 C193.854706,403.76238 220.994896,399.64989 220.994896,399.64989 C220.994896,399.64989 217.859964,378.961056 217.859964,378.961056 C217.747601,378.219521 218.239703,377.462249 219.055391,377.338649 C219.055391,377.338649 223.949524,376.597052 223.949524,376.597052 C224.691059,376.484689 225.459568,377.050944 225.571931,377.79248 C225.571931,377.79248 233.01037,426.882113 233.01037,426.882113 C233.122734,427.623648 232.556479,428.392157 231.814943,428.50452 C231.814943,428.50452 226.920811,429.246117 226.920811,429.246117 C226.105122,429.369716 225.410767,428.792225 225.298404,428.05069 C225.298404,428.05069 222.051109,406.620321 222.051109,406.620321 C222.051109,406.620321 194.910919,410.732812 194.910919,410.732812 C194.910919,410.732812 198.158214,432.16318 198.158214,432.16318 C198.270577,432.904716 197.778475,433.661988 196.962787,433.785587 C196.962787,433.785587 192.068654,434.527184 192.068654,434.527184 C191.327119,434.639547 190.55861,434.073292 190.446247,433.331757 Z" id="Path"></path>
            <path d="M253.108712,422.227906 C252.05765,422.629019 251.149451,421.932025 251.222245,420.860658 C251.222245,420.860658 254.737386,365.49352 254.737386,365.49352 C254.787153,364.992872 255.284086,364.40185 255.634441,364.268145 C255.634441,364.268145 256.335149,364.000736 256.335149,364.000736 C256.685504,363.867032 257.449846,363.976718 257.820504,364.316921 C257.820504,364.316921 297.188729,403.31864 297.188729,403.31864 C297.956785,404.069116 297.74385,405.193964 296.692787,405.595078 C296.692787,405.595078 292.278323,407.279753 292.278323,407.279753 C291.437472,407.600644 290.786531,407.367405 290.26558,406.843731 C290.26558,406.843731 282.257686,398.901957 282.257686,398.901957 C282.257686,398.901957 259.344515,407.646225 259.344515,407.646225 C259.173178,411.404303 258.90503,415.11905 258.733693,418.877127 C258.710667,419.447846 258.364027,420.222339 257.523177,420.54323 C257.523177,420.54323 253.108712,422.227906 253.108712,422.227906 Z M259.777563,400.577237 C259.777563,400.577237 277.295277,393.892017 277.295277,393.892017 C277.295277,393.892017 261.34956,377.98173 261.34956,377.98173 C261.34956,377.98173 260.999206,378.115434 260.999206,378.115434 C260.999206,378.115434 259.777563,400.577237 259.777563,400.577237 Z" id="Shape"></path>
            <path d="M314.414031,394.930353 C314.414031,394.930353 289.363445,352.063225 289.363445,352.063225 C288.985038,351.415686 289.162503,350.530174 289.874796,350.113925 C289.874796,350.113925 307.487876,339.821237 307.487876,339.821237 C315.258352,335.280346 325.199114,337.723578 329.702165,345.4293 C333.183515,351.386666 332.109421,358.616254 327.800252,364.174789 C327.800252,364.174789 346.2831,375.438113 346.2831,375.438113 C347.228308,376.015029 347.380483,377.315978 346.279665,377.959271 C346.279665,377.959271 341.228855,380.910851 341.228855,380.910851 C340.710824,381.213577 340.133096,381.116851 339.852225,380.933517 C339.852225,380.933517 321.212145,368.806537 321.212145,368.806537 C321.212145,368.806537 311.434295,374.520492 311.434295,374.520492 C311.434295,374.520492 321.08369,391.032754 321.08369,391.032754 C321.462098,391.680294 321.219879,392.603647 320.572339,392.982054 C320.572339,392.982054 316.363331,395.441704 316.363331,395.441704 C315.651037,395.857952 314.792439,395.577893 314.414031,394.930353 Z M308.309497,368.875969 C308.309497,368.875969 319.835704,362.140313 319.835704,362.140313 C324.044712,359.680664 325.654446,353.962272 323.043434,349.494248 C320.583784,345.28524 314.849406,343.945449 310.640398,346.405099 C310.640398,346.405099 299.114192,353.140755 299.114192,353.140755 C299.114192,353.140755 308.309497,368.875969 308.309497,368.875969 Z" id="Shape"></path>
            <path d="M365.725379,361.023535 C365.725379,361.023535 333.76172,323.030812 333.76172,323.030812 C333.278885,322.456904 333.30346,321.554119 333.934759,321.023 C333.934759,321.023 337.722553,317.836291 337.722553,317.836291 C338.296462,317.353456 339.24753,317.435422 339.730365,318.009331 C339.730365,318.009331 368.121047,351.755133 368.121047,351.755133 C368.121047,351.755133 383.67396,338.670311 383.67396,338.670311 C384.305259,338.139193 385.198936,338.269443 385.681771,338.843351 C385.681771,338.843351 388.337362,341.999846 388.337362,341.999846 C388.820197,342.573754 388.795622,343.47654 388.164323,344.007658 C388.164323,344.007658 367.733191,361.196574 367.733191,361.196574 C367.101892,361.727692 366.208214,361.597443 365.725379,361.023535 Z" id="Path"></path>
            <path d="M401.638197,327.511876 C401.638197,327.511876 364.342931,294.737105 364.342931,294.737105 C363.779558,294.242018 363.668592,293.345742 364.213188,292.726032 C364.213188,292.726032 384.016675,270.191128 384.016675,270.191128 C384.561271,269.571418 385.464376,269.566298 386.027748,270.061385 C386.027748,270.061385 389.182635,272.833873 389.182635,272.833873 C389.746008,273.32896 389.856973,274.225237 389.312377,274.844947 C389.312377,274.844947 373.667623,292.647521 373.667623,292.647521 C373.667623,292.647521 385.160424,302.7473 385.160424,302.7473 C385.160424,302.7473 398.379252,287.705251 398.379252,287.705251 C398.874339,287.141878 399.826952,287.080421 400.390325,287.575509 C400.390325,287.575509 403.545212,290.347997 403.545212,290.347997 C404.164922,290.892593 404.170041,291.795698 403.674954,292.35907 C403.674954,292.35907 390.456127,307.401119 390.456127,307.401119 C390.456127,307.401119 402.568638,318.045493 402.568638,318.045493 C402.568638,318.045493 418.213392,300.242919 418.213392,300.242919 C418.757988,299.623209 419.661093,299.618089 420.224466,300.113176 C420.224466,300.113176 423.323015,302.836156 423.323015,302.836156 C423.886388,303.331243 423.997353,304.227519 423.452758,304.847229 C423.452758,304.847229 403.649271,327.382134 403.649271,327.382134 C403.104675,328.001843 402.20157,328.006963 401.638197,327.511876 Z" id="Path"></path>
            <path d="M431.404438,284.115811 C430.538502,284.372284 429.815455,284.540966 429.128618,283.230859 C428.656963,282.142837 428.01832,281.039315 427.546665,279.951293 C427.07501,278.863271 427.374596,277.813153 428.383423,277.468888 C428.939482,277.284706 436.064964,274.914446 439.113975,270.009942 C441.846206,265.614997 440.913314,261.149327 437.664877,259.129852 C433.843185,256.753999 429.363709,258.561425 422.832062,263.243716 C416.054234,268.037896 409.510603,271.034814 402.122,266.441499 C397.153802,263.35289 392.714305,255.294262 399.010315,245.16678 C402.97007,238.797295 409.252018,236.079253 410.093857,235.719488 C410.729112,235.407916 411.93762,235.452723 412.497068,236.683635 C412.944626,237.668364 413.479976,238.795984 413.927533,239.780713 C414.438786,240.80504 414.306187,241.870658 413.178568,242.406008 C412.646606,242.693482 406.944814,245.330635 404.648156,249.024936 C401.321962,254.375304 403.549461,258.321125 405.90617,259.786234 C409.536777,262.043294 413.508389,260.626635 418.96061,256.686278 C426.547584,251.15876 433.970958,247.030835 441.74173,251.861735 C448.365995,255.97988 450.097485,265.269293 444.949804,273.549624 C440.118904,281.320396 432.993422,283.690657 431.404438,284.115811 Z" id="Path"></path>
            <path d="M464.210623,231.580239 C464.210623,231.580239 423.670673,214.413028 423.670673,214.413028 C423.670673,214.413028 418.903628,225.670289 418.903628,225.670289 C418.581926,226.429981 417.726394,226.719274 417.035765,226.426817 C417.035765,226.426817 413.168239,224.789059 413.168239,224.789059 C412.47761,224.496602 412.090009,223.680888 412.411711,222.921195 C412.411711,222.921195 424.987351,193.224128 424.987351,193.224128 C425.309053,192.464436 426.164585,192.175143 426.855215,192.4676 C426.855215,192.4676 430.72274,194.105358 430.72274,194.105358 C431.413369,194.397815 431.80097,195.213529 431.479268,195.973222 C431.479268,195.973222 426.712223,207.230482 426.712223,207.230482 C426.712223,207.230482 467.252173,224.397692 467.252173,224.397692 C467.942802,224.690149 468.301158,225.574927 468.008701,226.265556 C468.008701,226.265556 466.078486,230.823711 466.078486,230.823711 C465.78603,231.51434 464.901252,231.872696 464.210623,231.580239 Z" id="Path"></path>
            <path d="M453.242811,190.95774 C438.631754,187.564066 429.567825,173.216367 432.9615,158.605311 C436.355175,143.994254 450.832015,135.037318 465.443072,138.430993 C480.054128,141.824667 488.954978,156.211484 485.561303,170.822541 C482.167628,185.433597 467.853867,194.351415 453.242811,190.95774 Z M454.939648,183.652212 C465.386553,186.07869 475.846266,179.499553 478.255775,169.125703 C480.682253,158.678798 474.19314,148.162998 463.746234,145.736521 C453.372384,143.327012 442.693506,149.855243 440.267028,160.302148 C437.857519,170.675998 444.565798,181.242703 454.939648,183.652212 Z" id="Shape"></path>
            <path d="M492.13004,112.364386 C492.13004,112.364386 441.726108,126.525397 441.726108,126.525397 C440.676024,126.825105 439.926191,126.224897 439.926482,125.174897 C439.926482,125.174897 439.927877,120.149897 439.927877,120.149897 C439.928044,119.549897 440.45321,118.950043 440.978252,118.800189 C440.978252,118.800189 475.405769,109.734744 475.405769,109.734744 C475.405769,109.734744 475.405852,109.434744 475.405852,109.434744 C475.405852,109.434744 440.009247,97.1999192 440.009247,97.1999192 C439.559288,97.0497943 439.184601,96.5246902 439.184601,95.9246902 C439.184601,95.9246902 439.184601,94.5746903 439.184601,94.5746903 C439.185142,93.9746903 439.560288,93.4497944 440.010329,93.2999193 C440.010329,93.2999193 475.413763,80.934745 475.413763,80.934745 C475.413763,80.934745 475.413846,80.634745 475.413846,80.634745 C475.413846,80.634745 440.991324,71.7001908 440.991324,71.7001908 C440.466366,71.550045 439.941532,70.9498994 439.941699,70.3498994 C439.941699,70.3498994 439.943094,65.3248996 439.943094,65.3248996 C439.943385,64.2748996 440.693552,63.6751078 441.743468,63.9753992 C441.743468,63.9753992 492.139532,78.1643872 492.139532,78.1643872 C492.73949,78.3145537 493.189157,78.9146786 493.189157,79.5146785 C493.189157,79.5146785 493.189157,80.7146785 493.189157,80.7146785 C493.189157,81.2396785 492.813512,81.8395744 492.36347,81.9894495 C492.36347,81.9894495 455.084808,95.1791033 455.084808,95.1791033 C455.084808,95.1791033 455.084704,95.5541033 455.084704,95.5541033 C455.084704,95.5541033 492.356102,108.539448 492.356102,108.539448 C492.80606,108.689573 493.180893,109.289677 493.180748,109.814677 C493.180748,109.814677 493.180415,111.014677 493.180415,111.014677 C493.180248,111.614677 492.730082,112.214552 492.13004,112.364386 Z" id="Path"></path>
            <path d="M487.688545,42.8675402 C487.688545,42.8675402 438.25594,53.0725968 438.25594,53.0725968 C437.521428,53.2242321 436.797348,52.6844803 436.645712,51.9499691 C436.645712,51.9499691 436.266624,50.113691 436.266624,50.113691 C436.266624,50.113691 466.558115,10.6238113 466.558115,10.6238113 C466.558115,10.6238113 466.542951,10.5503602 466.542951,10.5503602 C466.542951,10.5503602 431.80057,17.7227106 431.80057,17.7227106 C431.066059,17.8743459 430.283691,17.4232087 430.116892,16.6152464 C430.116892,16.6152464 429.146426,11.9143745 429.146426,11.9143745 C428.994791,11.1798633 429.519379,10.3823314 430.25389,10.2306961 C430.25389,10.2306961 479.686495,0.0256394935 479.686495,0.0256394935 C480.421006,-0.125995821 481.145087,0.413756026 481.296722,1.14826724 C481.296722,1.14826724 481.690974,3.05799642 481.690974,3.05799642 C481.690974,3.05799642 450.371168,42.7601655 450.371168,42.7601655 C450.371168,42.7601655 450.386332,42.8336167 450.386332,42.8336167 C450.386332,42.8336167 486.157028,35.4489769 486.157028,35.4489769 C486.891539,35.2973416 487.673907,35.7484788 487.840706,36.5564411 C487.840706,36.5564411 488.796009,41.1838618 488.796009,41.1838618 C488.947644,41.918373 488.423056,42.7159049 487.688545,42.8675402 Z" id="Path"></path>
        </g>
        <path d="M1020.14941,1203.99411 C865.509681,1203.99411 740.149411,1078.63384 740.149411,923.994108 C740.149411,769.354378 865.509681,643.994108 1020.14941,643.994108 C1174.78914,643.994108 1300.14941,769.354378 1300.14941,923.994108 C1300.14941,1078.63384 1174.78914,1203.99411 1020.14941,1203.99411 Z" id="Oval" transform="translate(1020.149411, 923.994108) rotate(1.000000) translate(-1020.149411, -923.994108) "></path>
        <g id="NOW-OPEN" transform="translate(717.053647, 591.934158)" fill="currentColor" fill-rule="nonzero">
            <path d="M45.8073408,219.028456 C45.8073408,219.028456 0.71865451,196.340662 0.71865451,196.340662 C0.0486888889,196.003547 -0.183880718,195.130887 0.153233605,194.460921 C0.153233605,194.460921 0.996019412,192.786007 0.996019412,192.786007 C0.996019412,192.786007 49.2590552,180.632638 49.2590552,180.632638 C49.2590552,180.632638 49.2927666,180.565642 49.2927666,180.565642 C49.2927666,180.565642 17.6033928,164.620134 17.6033928,164.620134 C16.9334271,164.28302 16.6001495,163.443644 16.9709753,162.706682 C16.9709753,162.706682 19.128507,158.418902 19.128507,158.418902 C19.4656213,157.748937 20.3719932,157.449371 21.0419588,157.786485 C21.0419588,157.786485 66.1306451,180.474279 66.1306451,180.474279 C66.8006108,180.811393 67.0331804,181.684054 66.696066,182.354019 C66.696066,182.354019 65.8195688,184.09593 65.8195688,184.09593 C65.8195688,184.09593 16.6185812,195.777339 16.6185812,195.777339 C16.6185812,195.777339 16.5848697,195.844335 16.5848697,195.844335 C16.5848697,195.844335 49.2121955,212.261803 49.2121955,212.261803 C49.8821611,212.598917 50.2154387,213.438293 49.8446129,214.175255 C49.8446129,214.175255 47.7207927,218.396038 47.7207927,218.396038 C47.3836784,219.066004 46.4773064,219.36557 45.8073408,219.028456 Z" id="Path"></path>
            <path d="M61.0655564,145.151972 C49.9360035,135.095479 48.9716255,118.152007 59.0281186,107.022454 C69.0846116,95.8929016 86.0890962,95.0847363 97.218649,105.141229 C108.348202,115.197722 109.151002,132.096277 99.0945089,143.22583 C89.0380158,154.355382 72.1951092,155.208465 61.0655564,145.151972 Z M66.0938029,139.587195 C74.0514332,146.777588 86.3896223,146.099566 93.5297324,138.197583 C100.720125,130.239953 100.148033,117.896398 92.1904025,110.706006 C84.2884199,103.565896 71.7832875,104.093071 64.592895,112.050701 C57.4527849,119.952684 58.1918204,132.447085 66.0938029,139.587195 Z" id="Shape"></path>
            <path d="M142.583818,103.927488 C142.583818,103.927488 103.773128,68.7874688 103.773128,68.7874688 C102.960602,68.0578783 103.069777,67.1036347 103.958933,66.5451539 C103.958933,66.5451539 108.214182,63.8724244 108.214182,63.8724244 C108.722271,63.5532925 109.509601,63.6787388 109.915864,64.0435341 C109.915864,64.0435341 135.910908,88.3682938 135.910908,88.3682938 C135.910908,88.3682938 136.164953,88.2087279 136.164953,88.2087279 C136.164953,88.2087279 127.688493,51.729141 127.688493,51.729141 C127.576167,51.268291 127.821287,50.6714947 128.329377,50.3523628 C128.329377,50.3523628 129.472578,49.6343161 129.472578,49.6343161 C129.980667,49.3151842 130.624703,49.3534997 130.991074,49.6547838 C130.991074,49.6547838 160.299199,73.0499635 160.299199,73.0499635 C160.299199,73.0499635 160.553244,72.8903975 160.553244,72.8903975 C160.553244,72.8903975 149.800882,38.9916813 149.800882,38.9916813 C149.648664,38.4673201 149.877513,37.70361 150.385602,37.3844781 C150.385602,37.3844781 154.640851,34.7117486 154.640851,34.7117486 C155.530008,34.1532678 156.437012,34.4692476 156.741448,35.51797 C156.741448,35.51797 171.544914,85.7369706 171.544914,85.7369706 C171.737024,86.324843 171.468283,87.0250419 170.960194,87.3441738 C170.960194,87.3441738 169.944015,87.9824376 169.944015,87.9824376 C169.499437,88.261678 168.79189,88.263254 168.425519,87.9619699 C168.425519,87.9619699 137.421483,63.4178172 137.421483,63.4178172 C137.421483,63.4178172 137.103928,63.6172746 137.103928,63.6172746 C137.103928,63.6172746 145.942562,102.083556 145.942562,102.083556 C146.054889,102.544406 145.746257,103.181093 145.301679,103.460334 C145.301679,103.460334 144.2855,104.098598 144.2855,104.098598 C143.777411,104.41773 143.029972,104.355794 142.583818,103.927488 Z" id="Path"></path>
            <path d="M222.268313,37.0842003 C219.440243,22.3532118 228.874783,8.24645063 243.605771,5.41838092 C258.33676,2.59031121 272.398146,12.1863012 275.226216,26.9172897 C278.054286,41.6482781 268.51781,55.6218698 253.786822,58.4499395 C239.055834,61.2780092 225.096382,51.8151887 222.268313,37.0842003 Z M229.633807,35.6701654 C231.655877,46.2028222 241.913785,53.0923747 252.372787,51.0844452 C262.905444,49.0623754 269.882792,38.8639813 267.860722,28.3313245 C265.852792,17.8723227 255.552463,10.7618053 245.019806,12.7838752 C234.560804,14.7918047 227.625877,25.2111636 229.633807,35.6701654 Z" id="Shape"></path>
            <path d="M302.879331,50.9923649 C302.879331,50.9923649 304.654383,1.37410526 304.654383,1.37410526 C304.681196,0.624584722 305.304945,-0.0285329915 306.129417,0.000961819745 C306.129417,0.000961819745 323.218485,0.612308817 323.218485,0.612308817 C332.437588,0.942114434 339.739611,8.70813582 339.415168,17.7773343 C339.082681,27.071389 331.241708,34.3707305 322.097557,34.0436062 C322.097557,34.0436062 311.229509,33.654811 311.229509,33.654811 C311.229509,33.654811 310.599393,51.2685436 310.599393,51.2685436 C310.572579,52.0180642 309.873879,52.6685005 309.124358,52.6416871 C309.124358,52.6416871 304.252475,52.4673995 304.252475,52.4673995 C303.428002,52.4379047 302.852518,51.7418855 302.879331,50.9923649 Z M311.492281,26.3095097 C311.492281,26.3095097 321.835665,26.6795355 321.835665,26.6795355 C327.007356,26.8645484 331.504731,22.8227515 331.697788,17.4262036 C331.880119,12.3294639 327.67233,8.27643903 322.500639,8.09142613 C322.500639,8.09142613 312.157255,7.72140031 312.157255,7.72140031 C312.157255,7.72140031 311.492281,26.3095097 311.492281,26.3095097 Z" id="Shape"></path>
            <path d="M359.26716,56.5884133 C359.26716,56.5884133 370.716502,8.27656109 370.716502,8.27656109 C370.889453,7.5467748 371.628938,7.02832777 372.431703,7.21857364 C372.431703,7.21857364 401.623154,14.1366052 401.623154,14.1366052 C402.425919,14.326851 402.854093,15.1220193 402.681142,15.8518056 C402.681142,15.8518056 401.712617,19.9386088 401.712617,19.9386088 C401.539667,20.6683951 400.800182,21.1868421 399.997417,20.9965963 C399.997417,20.9965963 376.93617,15.5313514 376.93617,15.5313514 C376.93617,15.5313514 373.407974,30.4189916 373.407974,30.4189916 C373.407974,30.4189916 392.893268,35.0367777 392.893268,35.0367777 C393.623054,35.2097284 394.124206,36.0221918 393.951255,36.7519781 C393.951255,36.7519781 392.982731,40.8387813 392.982731,40.8387813 C392.792485,41.6415462 391.997317,42.0697195 391.267531,41.8967688 C391.267531,41.8967688 371.782237,37.2789827 371.782237,37.2789827 C371.782237,37.2789827 368.063795,52.9693879 368.063795,52.9693879 C368.063795,52.9693879 391.125041,58.4346328 391.125041,58.4346328 C391.927806,58.6248787 392.35598,59.4200469 392.183029,60.1498332 C392.183029,60.1498332 391.2318,64.1636578 391.2318,64.1636578 C391.058849,64.8934441 390.319364,65.4118911 389.516599,65.2216453 C389.516599,65.2216453 360.325148,58.3036137 360.325148,58.3036137 C359.522383,58.1133679 359.094209,57.3181996 359.26716,56.5884133 Z" id="Path"></path>
            <path d="M413.001325,73.4202268 C413.001325,73.4202268 435.141961,28.0603562 435.141961,28.0603562 C435.470945,27.3863611 436.340728,27.1432508 437.014723,27.472235 C437.014723,27.472235 438.699711,28.2946955 438.699711,28.2946955 C438.699711,28.2946955 451.436092,76.4071636 451.436092,76.4071636 C451.436092,76.4071636 451.503492,76.440062 451.503492,76.440062 C451.503492,76.440062 467.064444,44.5600935 467.064444,44.5600935 C467.393428,43.8860984 468.22871,43.5426902 468.970105,43.9045728 C468.970105,43.9045728 473.283673,46.0100716 473.283673,46.0100716 C473.957669,46.3390558 474.268178,47.2417371 473.939194,47.9157323 C473.939194,47.9157323 451.798558,93.2756029 451.798558,93.2756029 C451.469574,93.949598 450.599791,94.1927083 449.925796,93.8637241 C449.925796,93.8637241 448.173409,93.0083652 448.173409,93.0083652 C448.173409,93.0083652 435.897605,43.9523039 435.897605,43.9523039 C435.897605,43.9523039 435.830206,43.9194055 435.830206,43.9194055 C435.830206,43.9194055 419.808676,76.7429672 419.808676,76.7429672 C419.479692,77.4169623 418.64441,77.7603705 417.903015,77.3984879 C417.903015,77.3984879 413.656846,75.3258875 413.656846,75.3258875 C412.982851,74.9969033 412.672341,74.0942219 413.001325,73.4202268 Z" id="Path"></path>
        </g>
        <path d="M729.411163,815.785905 C716.860027,849.475747 710,885.936751 710,924 C710,1036.91514 770.369623,1135.7302 860.593217,1189.92953 M1310.41796,815.328354 C1288.64055,757.155677 1249.88536,707.273466 1200.18375,671.713088" id="Shape" stroke="currentColor" stroke-width="8"></path>
    </g>
  </svg>
</template>