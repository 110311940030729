<script setup lang="ts">
import { Map } from 'mapbox-gl'

useHead({
  link: [
    {
      rel: "preload",
      href: 'https://api.mapbox.com/mapbox-gl-js/v2.8.1/mapbox-gl.css',
      as: 'style',
    },
  ]
})

const config = useRuntimeConfig()

const MAPBOX_ACCESS_TOKEN = config.public.mapboxAccessToken

const coordinates: [number, number] = [-4.758456, 50.332555]

onMounted(() => {
  const map = new Map({
    accessToken: MAPBOX_ACCESS_TOKEN,
    container: 'map', // container ID
    style: 'mapbox://styles/mapbox/light-v11', // style URL
    center: coordinates, // starting position [lng, lat]
    zoom: 8, // starting zoom
  })

  map.doubleClickZoom.disable()
  map.dragPan.disable()
  map.dragRotate.disable()
  map.keyboard.disable()
  map.scrollZoom.disable()
  map.touchZoomRotate.disableRotation()
  map.touchPitch.disable()

  map.on('load', () => {
    map.loadImage(
      '/icons/marker.png',
      (error, image) => {
      if (error) throw error;
      
      // Add the image to the map style.
      if (!image) return

      map.addImage('custom-marker', image);
      
      map.addSource("locations", {
        "type": "geojson",
        "data": {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: coordinates
              },
              properties: {
                title: 'Kind & Conscioues',
                description: 'Charlestown, Cornwall, UK',
                'marker-color': '#3bb2d0',
                'marker-symbol': 'marker'
              }
            }
          ]
        }
      });

      map.addLayer({
        "id": "markers",
        "type": "symbol",
        "source": "locations",
        "layout": {
          "icon-image": "custom-marker",
          "icon-size": 0.25
        }
      });
    })
  });

  // Remove the DOM element with class 'mapboxgl-control-container':
  const mapboxglControlContainer = document.querySelector('.mapboxgl-control-container')

  if (mapboxglControlContainer) {
    mapboxglControlContainer.remove()
  }
})
</script>

<template>
  <div class="grid grid-cols-1 bg-blue-800 bg-opacity-70 overscroll-y-contain overflow-hidden">
    <section id="map" class="h-128 w-full"></section>
  </div>
</template>

<style>
.mapboxgl-control-container {
  display: none;
  visibility: hidden;
  height: 0;
  width: 0;
}
</style>