<script setup lang="ts">
import { useWindowScroll } from '@vueuse/core'

import { MapPinIcon } from '@heroicons/vue/20/solid'

const { y } = useWindowScroll()

const show = computed(() => {
  return y.value < 300
})

const route = useRoute()

const isHome = computed(() => {
  return route.name === 'index'
})
</script>

<template>
  <div class="w-full h-full bg-blue-100">
    <!-- Start The Mobile Navigation -->
    <TheMobileTopNavigation 
      class="md:hidden transition-all duration-300 ease-in-out"
    />
    <!-- End The Mobile Navigation -->
    
    <!-- Start Social Links -->
    <div class="hidden md:block md:ml-16 h-16 fixed inset-0">
      <SocialLinks />
    </div>
    <!-- End Social Links -->

    <!-- Start Vertical Location -->
    <div class="hidden md:block md:ml-5 md:mt-16 w-20 fixed inset-0">
      <a 
        href="https://www.google.com/maps/place/Kind+%26+Conscious/@50.3325396,-4.7584768,16.5z/data=!4m2!3m1!1s0x0:0xb8fccde704a447b8?sa=X&ved=2ahUKEwighY3ikcT5AhWCWMAKHRreBpgQ_BJ6BAhIEAU" 
        target="_blank"
        class="block pb-3 text-blue-800 hover:text-blue-700"
        aria-hidden="true"
        aria-label="Map Location | Kind & Conscious"
      >
        <span class="sr-only">Where To Find Us - Kind & Conscious</span>
        <MapPinIcon
          class="h-5 w-5" 
        />
      </a>
       
      <address 
        class="text-sm font-sans font-medium tracking-wide transform rotate-180 text-right mr-20" 
        :style="{ writingMode: 'vertical-rl' }"
      >
        <span class="mt-6 flex gap-2 font-bold text-blue-800 not-italic">
          No. 2 The Old Workshop
          <span 
            aria-hidden="true" 
            class="text-blue-500"
          >/</span>
          Charlestown
          <span 
            aria-hidden="true" 
            class="text-blue-500"
          >/</span>
          Cornwall
        </span>
      </address>
    </div>
    <!-- End Vertical Location -->

    <!-- Start Header -->
    <header class="w-full h-screen sm:p-3 md:p-16 fixed top-0">
      <div class="grid grid-cols-1 md:grid-cols-5 xl:grid-cols-7 gap-10 w-full h-full lg:max-w-screen-2xl xl:max-w-screen-3xl">
        <div class="col-span-1 md:col-span-5 xl:col-span-5 h-full md:h-auto order-1 relative">
          <div 
            class="absolute inset-0 w-full h-full bg-blue-500 bg-cover bg-top md:rounded-tl-none md:rounded-b-8xl md:rounded-tr-8xl ocean-background"
          />
          <div class="absolute inset-0 w-full h-full z-20 bg-blue-500 bg-cover bg-opacity-60 md:rounded-tl-none md:rounded-b-8xl md:rounded-tr-8xl">
            <div 
              class="h-full transition-opacity ease-in-out duration-500"
              :class="{ 
                'opacity-100': show,
                'opacity-0': !show
              }"
            >
              <slot name="header"></slot>
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- End Header -->

    <!-- Start Contents Wrapper -->
    <div 
      class="md:hidden w-full h-full relative z-20 transition-all duration-500 ease-in-out"
      :class="{
        'mt-10/6': isHome,
        'mt-4/5': !isHome
      }"
    >
      <!-- Start Main Contents -->
      <main class="w-full h-full">
        <div class="w-full flex flex-col justify-center items-center pt-5 px-5 bg-blue-100">
          <div class="w-10 h-1.5 bg-blue-200 rounded-full"></div>
        </div>
        <slot name="main"></slot>
      </main>
      <!-- End Main Contents -->

      <!-- Start The Footer -->
      <TheFooter />
      <!-- End The Footer -->
    </div>
    <!-- End Contents Wrapper -->
  </div>
</template>

<style lang="css" scoped>
.ocean-background {
  background-repeat: no-repeat;
  background-image: url("/images/ocean.jpg");
  background-image: image-set(
    url("/images/ocean.avif") type("image/avif"),
    url("/images/ocean.webp") type("image/webp"),
    url("/images/ocean.jpg") type("image/jpeg"),

  );
  background-image: -webkit-image-set(
    url("/images/ocean.avif") type("image/avif"),
    url("/images/ocean.webp") type("image/webp"),
    url("/images/ocean.jpg") type("image/jpeg"),
  );
}
</style>