<script setup lang="ts">
import * as Yup from 'yup'

import { Form as VeeForm, Field, ErrorMessage } from 'vee-validate'

const subscribeFormRef = ref<HTMLFormElement>()

const email = ref("")

const subscribeFormSchema = Yup.object().shape({
  email: Yup.string().email().required().label('Email Address')
})

const resetSubmission = () => {
  message.value = ""
  email.value = ""
  subscribeFormRef.value?.reset()
}

const message = ref("")

const status = ref("idle")

const subscribeSubmission = async (values: { email: string }) => {
  status.value = "subscribing"

  try {
    const subscriber = await $fetch('/api/subscribe', {
      method: 'POST',
      body: JSON.stringify(values)
    })

    if (subscriber.statusCode === 200) {
      message.value = "Perfect. You've been subscribed successfully!"
    }

    if (subscriber.statusCode === 400) {
      message.value = "Oops. Something went wrong. Please try again."
    }

    if (subscriber.statusCode === 500) {
      message.value = "Looks like you've already subscribed!"
    }
  } catch(e) {
    message.value = "Oops. Something went wrong. Please try again."
  }

  status.value = "idle"

  setTimeout(() => {
    resetSubmission()
  }, 2000)
}
</script>

<template>
  <div class="grid grid-cols-1">
    <VeeForm 
      v-slot="{ handleSubmit }" 
      :validation-schema="subscribeFormSchema"
      as="div" 
      class="col-span-1"
    >
      <form ref="subscribeFormRef" @submit.prevent="handleSubmit($event, subscribeSubmission)" class="sm:mx-auto sm:max-w-lg mt-3 md:flex">
        <div class="min-w-0 flex-1">
          <label 
            for="email"
            class="sr-only"
          >
            Your Email
          </label>
          <Field 
            id="email" 
            name="email" 
            type="email"
            class="block w-full py-3 px-3 text-base rounded-md font-sans placeholder:gray-500 shadow-sm focus:ring-blue-800 focus:border-blue-800 md:flex-1 border-gray-300" 
            placeholder="Enter your email"
            v-model="email"
            required 
          />
          <ErrorMessage name="email_addr" />
        </div>
        <div class="relative mt-3 md:mt-0 md:ml-3">
          <button
            type="submit"
            class="w-full px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800 md:flex-shrink-0 md:items-center md:w-auto"
            :class="{
              'bg-opacity-50 cursor-not-allowed': status === 'subscribing',
              'bg-opacity-100 cursor-pointer': status === 'idle',
            }"
          >
            <span
              class="transition-all duration-200 ease-in-out"
              :class="{
                'opacity-100': status === 'idle',
                'opacity-0': status === 'subscribing',
              }"
            >
              Subscribe
            </span>
            <div
              class="absolute inset-0 flex items-center w-full h-full pointer-events-none transition-all duration-500 ease-in-out"
              :class="{
                'opacity-0': status === 'idle',
                'opacity-100': status === 'subscribing',
              }"
            >
              <svg 
                class="animate-spin mx-auto h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg" 
                fill="none" 
                viewBox="0 0 24 24"
              >
                <circle 
                  class="opacity-25" 
                  cx="12" 
                  cy="12" 
                  r="10" 
                  stroke="currentColor" 
                  stroke-width="4"
                />
                <path 
                  class="opacity-75" 
                  fill="currentColor" 
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
            </div>
          </button>
        </div>
      </form>
    </VeeForm>
    <div 
      v-show="message.length > 0" 
      class="col-span-1"
    >
      <div
        class="rounded-md bg-blue-50 p-4 mt-3"
      >
        <div class="flex">
          <div class="flex-shrink-0">
            <svg 
              class="h-5 w-5 text-blue-400" 
              xmlns="http://www.w3.org/2000/svg" 
              viewBox="0 0 20 20" 
              fill="currentColor" 
              aria-hidden="true"
            >
              <path 
                fill-rule="evenodd" 
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" 
                clip-rule="evenodd" 
              />
            </svg>
          </div>
          <div class="ml-3 flex-1 md:flex md:justify-between">
            <p class="text-sm text-blue-700">
              {{ message }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>