<script setup lang="ts">
import { XCircleIcon, PlusSmallIcon, MinusSmallIcon } from '@heroicons/vue/24/outline'

import { type Product, type ProductVariant } from '@/types';

const route = useRoute()

const { removeProductFromCart, updateProductInCart } = useCart()

const props = defineProps<{
  product: Product,
  productVariant: ProductVariant,
  quantity: number
  close: () => void
}>()

const { product, productVariant, quantity } = toRefs(props)

const { 
  title: productTitle, 
  handle: productHandle,
  imageSrc: productImageSrc, 
  imageAlt: productImageAltText 
} = useProductDetail(product)

const {
  handle: productVariantHandle,
  imageSrc: productVariantImageSrc, 
  imageAlt: productVariantImageAltText 
} = useProductVariantDetail(productVariant)

const title = computed<string>(() => {
  return productTitle
})

const handle = computed<string>(() => {
  return productVariantHandle || productHandle
})

const src = computed<string>(() => {
  return productVariantImageSrc.value || productImageSrc.value
})

const alt = computed<string>(() => {
  return productVariantImageAltText.value || productImageAltText.value
})

const to = computed(() => {
  if (route.params.collectionHandle) {
    return {
      name: 'collections-collectionHandle-products-productHandle',
      params: {
        collectionHandle: route.params.collectionHandle,
        productHandle: handle.value
      }
    }
  } else {
    return {
      name: 'products-productHandle',
      params: {
        productHandle: handle.value
      }
    }
  }
})

const adjustProductVariantQuantity = (adjustment: number): void => {
  const newQuantity = quantity.value + adjustment

  // If the adjuments is negative:
  if (adjustment < 0) {
    if (newQuantity <= 0) {
      removeProductFromCart(product.value, productVariant.value, quantity.value)
    } else {
      updateProductInCart(product.value, productVariant.value, newQuantity)
    }
  }

  // If the adjustment is positive:
  if (adjustment > 0) {
    if (newQuantity > productVariant.value.inventoryQuantity) {
      return
    }

    updateProductInCart(product.value, productVariant.value, newQuantity)
  }

  return
}
</script>

<template>
  <img 
    :src="src" 
    :alt="alt" 
    class="flex-none w-16 h-16 rounded-md border border-gray-200 object-cover" 
    decoding="async"
    fetchpriority="low"
    loading="lazy"
  />
  <div class="ml-4 flex-auto">
    <h3 class="font-medium text-blue-600">
      <NuxtLink
        :to="to"
        @click.native="close"
      >
        {{ title }}
      </NuxtLink>
    </h3>
    <p class="text-gray-400 text-sm">Qty {{ quantity }}</p>
  </div>
  <div>
    <span class="isolate inline-flex rounded-md shadow-sm">
      <button 
        type="button" 
        class="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
        @click="adjustProductVariantQuantity(-1)"
      >
        <span class="sr-only">Remove one quantity of product from Cart</span>
        <MinusSmallIcon
          class="h-5 w-5" 
          aria-hidden="true" 
        />
      </button>
      <button 
        type="button" 
        class="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
        @click="adjustProductVariantQuantity(1)"
      >
        <span class="sr-only">Add one quantity of product from Cart</span>
        <PlusSmallIcon 
          class="h-5 w-5" 
          aria-hidden="true" 
        />
      </button>
    </span>
  </div>
  <div class="flex items-start justify-end p-2">
    <button 
      type="button" 
      class="text-sm font-medium text-red-600 hover:text-red-500"
      aria-label="Remove Item from Cart"
      @click="removeProductFromCart(product, productVariant, quantity)"
    >
      <XCircleIcon class="w-6 h-6" />
      <span 
        class="sr-only" 
        aria-hidden="true"
      >
        Remove
      </span>
    </button>
  </div>
</template>