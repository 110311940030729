<script setup lang="ts">
import  { type PropType } from 'vue'

import { computed } from 'vue'

import { ShoppingBagIcon, XMarkIcon } from '@heroicons/vue/24/outline'

import { Popover, PopoverButton, PopoverOverlay, PopoverPanel } from '@headlessui/vue'

const { cartStore } = useCart()

const router = useRouter()

defineProps({
  fixed: {
    type: Boolean as PropType<boolean>,
    required: false,
    default: () => {
      return false
    }
  }
})

const goToCart = (close: () => void) => {
  router.push({ name: 'cart' })
  close()
}

const items = computed(() => {
  return cartStore.getAllItems
})

const totalProducts = computed(() => {
  return cartStore.getTotalProducts
})
</script>

<template>
  <!-- Start Populated Shopping Cart -->
  <Popover class="ml-4 flow-root text-sm lg:relative lg:ml-6">
    <PopoverButton
      class="group h-12 p-3 text-white rounded-full flex items-center"
      :class="{
        'bg-blue-500': fixed,
        'bg-white bg-opacity-10': !fixed
      }"
    >
      <ShoppingBagIcon 
        class="flex-shrink-0 h-6 w-auto current-color"
        aria-hidden="true" 
        aria-label="Shopping Basket"
        :data-total-products="totalProducts"
      />
      <span 
        class="w-5 text-sm font-medium text-white text-center tabular-nums transition-all duration-500 ease-in-out"
      >
        {{ totalProducts }}
      </span>
      <span class="sr-only">
        items in cart, view bag
      </span>
    </PopoverButton>

    <PopoverOverlay 
      v-if="totalProducts > 0" 
      class="fixed inset-0 bg-blue-500 opacity-80" 
    />

    <transition 
      enter-active-class="transition ease-out duration-200" 
      enter-from-class="opacity-0" 
      enter-to-class="opacity-100" 
      leave-active-class="transition ease-in duration-150" 
      leave-from-class="opacity-100" 
      leave-to-class="opacity-0"
    >
      <PopoverPanel
        v-show="totalProducts > 0"
        v-slot="{ close }" 
        class="absolute top-16 inset-x-0 m-8 mx-12 bg-white rounded-tl-none rounded-b-3xl rounded-tr-3xl lg:top-full lg:left-auto lg:right-0 lg:mt-3 lg:-mr-1.5 lg:w-80 lg:rounded-lg lg:ring-1 lg:ring-black lg:ring-opacity-5"
      >
        <div>
          <div
            class="w-full flex justify-between pt-4 px-4"
          >
            <div>
              <h2 class="sr-only">
                Your Shopping Cart
              </h2>
            </div>
            <button 
              type="button"
              @click="close"
              class="flex justify-center items-center text-sm font-medium bg-blue bg-opacity-30 text-blue-600 hover:text-blue-500 rounded-full px-2 py-0.5"
            >
              Close
              <XMarkIcon class="h-4 w-4 ml-0.5" />
            </button>
          </div>
          <form class="max-w-2xl">
            <ul 
              role="list" 
              class="divide-y divide-gray-200 max-h-100 overflow-y-scroll p-4"
            >
              <li 
                v-for="item in items" 
                :key="item.id" 
                class="py-3 flex items-center"
              >
                <CartPopoverItem 
                  :product="item.product"
                  :product-variant="item.variant"
                  :quantity="item.quantity"
                  :close="close"
                />
              </li>
            </ul>
            
            <div
              class="p-4"
            >
              <CartCheckoutButton 
                v-if="cartStore.cart.checkoutUrl"
                :checkout-url="cartStore.cart.checkoutUrl"
              />

              <div class="w-full grid grid-cols-1 gap-y-4 py-4">
                <div class="col-span-1 flex items-center justify-center">
                  <button 
                    type="button"
                    @click="goToCart(close)"
                    class="text-sm text-center font-medium text-blue-600 hover:text-blue-500"
                  >
                    View Your Shopping Basket
                  </button>
                </div>
                <div class="col-span-1 flex items-center justify-center">
                  <button 
                    type="button"
                    @click="close"
                    class="text-sm text-center font-medium text-blue-600 hover:text-blue-500"
                  >
                    Continue Shopping
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
  <!-- End Populated Shopping Cart  -->
</template>