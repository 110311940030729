<script setup lang="ts">
import { defineAsyncComponent } from 'vue';

import { MapPinIcon } from '@heroicons/vue/20/solid'

const footer = {
  about: {
    name: 'About',
    pages: [
      {
        display: 'Our Story',
        name: 'story'
      },
      {
        display: 'Sustainability',
        name: 'sustainability'
      },
      {
        display: 'Brands A-Z',
        name: 'brands'
      }
    ]
  },
  legal: {
    name: 'Legal',
    pages: [
      {
        display: 'Returns Policy',
        name: 'returns'
      },
      {
        display: 'Privacy Policy',
        name: 'privacy'
      },
      {
        display: 'Terms & Conditions',
        name: 'terms'
      }
    ]
  }
}

const brands = [
  {
    name: 'Bird Eyewear',
    logo: defineAsyncComponent(() =>
      import('~/components/Bird.vue')
    ),
    logoClasses: 'h-16 max-h-16',
    handle: 'bird-sunglasses',
  },
  {
    name: 'Green & Blue',
    logo: defineAsyncComponent(() =>
      import('~/components/GreenAndBlue.vue')
    ),
    logoClasses: 'h-12 max-h-12',
    handle: 'green-and-blue',
  },
  {
    name: 'Frond',
    logo: defineAsyncComponent(() =>
      import('~/components/Frond.vue')
    ),
    logoClasses: 'h-16 max-h-16',
    handle: 'frond',
  },
  {
    name: 'Herd',
    logo: defineAsyncComponent(() =>
      import('~/components/Herd.vue')
    ),
    logoClasses: 'h-10 max-h-12',
    handle: 'herd'
  },
  {
    name: 'Keynvor',
    logo: defineAsyncComponent(() =>
      import('~/components/Keynvor.vue')
    ),
    logoClasses: 'h-12 max-h-12',
    handle: 'keynvor-soy-candles'
  },
  {
    name: 'DickPearce',
    logo: defineAsyncComponent(() =>
      import('~/components/DickPearce.vue')
    ),
    logoClasses: 'h-24 max-h-28',
    handle: 'dick-pearce'
  },
  {
    name: 'Herboo',
    logo: defineAsyncComponent(() =>
      import('~/components/Herboo.vue')
    ),
    logoClasses: 'h-12 max-h-12',
    handle: 'herboo'
  },
  {
    name: 'Solmate',
    logo: defineAsyncComponent(() =>
      import('~/components/Solmate.vue')
    ),
    logoClasses: 'h-18 max-h-18',
    handle: 'solmate'
  }
]
</script>

<template>
  <footer>
    <div class="w-full h-full bg-blue-500 bg-opacity-10 flex-col justify-center items-center">
      <div class="mx-auto sm:max-w-md text-center lg:ml-0 lg:mr-auto lg:text-left p-6">
        <div>
          <KindAndConsciousStampLogo 
            class="h-12 w-auto mx-auto text-transparent"
            aria-label="Kind & Conscious Logo"
            aria-hidden="true"
          />
          <div class="sm:max-w-lg sm:mx-auto lg:mx-0">
            
            <p class="text-base text-center lg:text-left font-medium text-white md:text-gray-900 mt-2">
              Sign up for our newsletter to stay up to date.
            </p>
            <client-only>
              <Newsletter />
            </client-only>
            <p class="mt-3 text-sm text-white">
              We care about the protection of your data. <span class="underline">We'll never hand your details over to anyone.</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 px-6 py-10 bg-blue-500 bg-opacity-10">
      <div class="grid grid-cols-2 gap-1 md:grid-cols-3 lg:mt-8">
        <NuxtLink 
          v-for="brand in brands"
          :key="brand.handle"
          :to="{
            name: 'collections-collectionHandle',
            params: {
              collectionHandle: brand.handle,
            }
          }"
          :alt="`Click to explore the brand ${brand.name} products`"
          :title="`Click to explore the brand ${brand.name} products`"
          :aria-label="`Click to explore the brand ${brand.name} products`"
          class="col-span-1 flex justify-center items-center bg-gray-50 bg-opacity-10 hover:bg-opacity-30 py-8 px-8 h-28 select-none"
        >
          <span class="sr-only">
            {{ brand.name }}
          </span>
          <component 
            :is="brand.logo" 
            class="w-auto text-blue-800 hover:bg-blue-600"
            :class="brand.logoClasses"
          />
        </NuxtLink>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-8 bg-blue-800 bg-opacity-70">
      <MapboxBlock />
    </div>
    <div class="grid grid-cols-1 gap-3 py-10 bg-blue-800 bg-opacity-70">
      <div class="text-center py-3">
        <h3 class="font-semibold text-white tracking-wider uppercase">
          {{ footer.about.name }}
        </h3>
        <ul role="list" class="mt-4 space-y-4">
          <li 
            v-for="page in footer.about.pages" 
            :key="page.display"
          >
            <NuxtLink 
              :to="{ 'name': page.name }" 
              class="text-sm text-white hover:text-blue-700"
            >
              {{ page.display }}
            </NuxtLink>
          </li>
        </ul>
      </div>
      <div class="text-center py-3">
        <h3 class="font-semibold text-white tracking-wider uppercase">
          {{ footer.legal.name }}
        </h3>
        <ul role="list" class="mt-4 space-y-4">
          <li 
            v-for="page in footer.legal.pages" 
            :key="page.display"
          >
            <NuxtLink 
              :to="{ 'name': page.name }" 
              class="text-sm text-white hover:text-blue-700"
            >
              {{ page.display }}
            </NuxtLink>
          </li>
        </ul>
      </div>
      <div class="grid grid-cols-1 gap-8">
        <div class="flex justify-center lg:justify-start items-end py-3">
          <div class="w-30 flex items-center justify-center">
            <a 
              href="https://www.google.com/maps/place/Kind+%26+Conscious/@50.3325396,-4.7584768,16.5z/data=!4m2!3m1!1s0x0:0xb8fccde704a447b8?sa=X&ved=2ahUKEwighY3ikcT5AhWCWMAKHRreBpgQ_BJ6BAhIEAU" 
              target="_blank"
              rel="noopener noreferrer"
              title="You can find Kind & Conscious at Unit 2, The Old Workshop, Charlestown, Cornwall"
              aria-label="You can find Kind & Conscious at Unit 2, The Old Workshop, Charlestown, Cornwall"
              aria-hidden="true"
              class="group relative h-24 w-24 cursor-pointer"
            >
              <div class="absolute inset-0">
                <div class="h-full w-full flex items-center justify-center">
                  <MapPinIcon
                    class="h-7 w-7 text-white group-hover:text-blue-800"
                  />
                </div>
              </div>
              <OpenSoonRoundal 
                class="h-24 text-white"
              />
            </a>
          </div>
        </div>
      </div>
      <hr class="border-1 border-blue-500 border-opacity-20"/>
      <div class="w-full flex justify-center items-center">
          <address>
            <a
            href="https://www.google.com/maps/place/Kind+%26+Conscious/@50.3325396,-4.7584768,16.5z/data=!4m2!3m1!1s0x0:0xb8fccde704a447b8?sa=X&ved=2ahUKEwighY3ikcT5AhWCWMAKHRreBpgQ_BJ6BAhIEAU" 
            target="_blank"
            rel="noopener noreferrer"
            title="Find us at Unit 2, The Old Workshop, Charlestown, Cornwall"
            aria-label="Find us at Unit 2, The Old Workshop, Charlestown, Cornwall"
            class="w-full flex items-center justify-center not-italic text-sm text-center leading-4 text-white md:mt-0 md:order-1"
          >
            <MapPinIcon 
              class="h-4 w-4 mr-1"
              aria-hidden="true"
            />
            
            Find us at Unit 2, The Old Workshop, Charlestown, Cornwall
          </a>
        </address>
      </div>
      <div class="w-full flex justify-center items-center">
        <address>
          <a
            href="https://what3words.com/supplier.albatross.nearing" 
            target="_blank"
            rel="noopener noreferrer"
            title="Kind & Conscious What3Words location what3words///supplier.albatross.nearing"
            aria-label="Kind & Conscious What3Words location what3words///supplier.albatross.nearing"
            
          >
            <span class="w-full flex items-center justify-center not-italic text-sm text-center leading-4 text-white md:mt-0 md:order-1">
              what3words///supplier.albatross.nearing
            </span>
          </a>
        </address>
      </div>
      <hr class="border-1 border-blue-500 border-opacity-20"/>
      <div class="flex justify-center items-center space-x-3 py-6">
        <div class="w-20">
          <a 
            href="https://www.refill.org.uk/"
            target="_blank"
            rel="noopener noreferrer"
            title="Water Refills Kind & Conscious, Charlestown UK | refill.org.uk"
            aria-label="Water Refills Kind & Conscious, Charlestown UK | refill.org.uk"
            alt="Water Refills Kind & Conscious, Charlestown UK | refill.org.uk" 
          >
            <RefillOrg 
              title="Free Water Refills | Kind & Conscious UK"
              class="h-20 w-auto mx-auto lg:ml-0 lg:mr-auto text-white hover:text-blue-800"
            />
          </a>
        </div>
        <div class="w-20">
          <a 
            href="https://www.cornwallwildlifetrust.org.uk/"
            target="_blank"
            rel="noopener noreferrer"
            title="Creating a Cornwall where nature thrives | cornwallwildlifetrust.org.uk"
            aria-label="Creating a Cornwall where nature thrives | cornwallwildlifetrust.org.uk"
            alt="Creating a Cornwall where nature thrives | cornwallwildlifetrust.org.uk" 
          >
            <CornwallWildlifeTrustIcon
              title="Proud Member of Cornwall Wildlife Trust | Kind & Conscious UK"
              class="h-20 w-auto mx-auto lg:ml-0 lg:mr-auto text-blue-800"
            />
          </a>
        </div>
      </div>
      <div class="py-3">
        <div class="md:hidden h-16">
          <SocialLinks class="text-white hover:text-blue-100" />
        </div>
        <p class="pb-3 text-sm text-center text-white md:mt-0 md:order-1 sr-only">
          Find us at Unit 2, The Old Workshop, Charlestown, Cornwall
        </p>
        <p class="pb-3 text-sm text-center text-white md:mt-0 md:order-1">
          &copy; {{ new Date().getFullYear() }} Kind & Conscious. 
          All rights reserved.
        </p>
      </div>
    </div>
  </footer>
</template>